import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import { CtaGetDemo } from "components/cta"

import { HomeHero, HomeCustomers, HomeProducts } from "../sections/home"
import { HomeIntegrations } from "src/sections/cannabis"

const IndexPage = ({ data: { home } }) => {
  const meta = useMeta(home)
  const {
    home_hero,
    home_products,
    home_integrations,
    home_customers,
    cta_get_demo,
  } = useContentfulSection(home.sections)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <HomeHero {...home_hero} />
        <HomeProducts {...home_products} />
        <HomeIntegrations {...home_integrations} />
        <HomeCustomers {...home_customers} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    home: contentfulPage(pageId: { eq: "home" }) {
      ...Page
    }
  }
`

export default IndexPage
